<template>
    <div>
        <div class="actions-tab in-tab snp-actions">
            <b-dropdown
                variant="link"
                class="info"
                right
                toggle-class="text-decoration-none"
                no-caret
            >
                <template #button-content>
                    <div class="btn-download">
                        <div>
                <span class="left">
                <i class="icon icon-info"></i>
                </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div
                        class="info-button"
                        @click="downloadStaticFile(2)"
                    >
                        <span>
                            Режим «Мониторинг СНП»
                            <i class="icon icon-folder"></i>
                        </span>
                    </div>
                    <div class="info-title">
                        <span>Мониторинг СНП</span>
                    </div>
                    <div class="info-text">
                        <p>
                            Данный модуль предназначен для сбора и анализа данных по формам
                            мониторинга СНП по приказу Первого заместителя Премьер-Министра
                            Республики Казахстан - Министра регионального развития Республики
                            Казахстан от 24 сентября 2013 года № 239/ОД. .
                        </p>
                        <div class="info-subtitle">
                            <span>Анализ формы</span>
                        </div>
                        <p>
                            Раздел предназначен для просмотра и выгрузки анализа форм
                            мониторинга по области текущего года и прошлых лет.
                        </p>
                    </div>
                </b-dropdown-text>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <i class="icon icon-download" style="color: #01AC50; margin-left: 9px;"></i>
                            <span style="margin-right: 15px;">Скачать</span>
                        </div>
                        <i class="icon icon-keyboard"></i>
                    </div>
                </template>
                <b-dropdown-item @click="downloadReport">Анализ формы</b-dropdown-item>
                <b-dropdown-item @click="downloadAllReports">Анализ формы по отраслям</b-dropdown-item>
                <b-dropdown-item @click="downloadAllHistory">Анализ формы по годам</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"> <i class="icon icon-filter" /> Фильтр </span>
                        <span class="rc">
              <i class="icon icon-keyboard" />
            </span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>

                        <div class="filter-block">
                            <b-form-group :label="filterLabels[0].name_ru">
                                <multiselect
                                    v-model="selectedReportType"
                                    :options="reportTypes"
                                    placeholder="Форма"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="name"
                                />
                            </b-form-group>
                        </div>

                        <div class="filter-block">
                            <b-form-group :label="filterLabels[1].name_ru">
                                <multiselect
                                    v-model="selectedYear"
                                    :options="selectedReportType.id === 18 ? years.filter(item => item > 2020) : years"
                                    placeholder="Год"
                                    :allow-empty="false"
                                    :show-labels="false"
                                />
                            </b-form-group>
                        </div>

                        <div class="filter-block">
                            <b-button variant="primary" @click="applyFilters">
                                Применить
                            </b-button>
                            <b-button variant="secondary" @click="resetFilters">
                                Сбросить
                            </b-button>
                        </div>
                    </div>
                </b-dropdown>
                <div v-if="loadedReportType" class="selected">
                    {{ loadedReportType.name }}
                </div>
                <div v-if="loadedYear" class="selected">{{ loadedYear }}</div>
            </div>
        </div>

        <div class="table-container" v-if="!loading">
            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered b-table-no-border-collapse">
                    <thead>
            <th
              :rowspan="+maxRowSpan"
              class="arrow-th"
            />
                    <template v-for="(tr, index) of formHeaders">
                        <tr :key="index">
                            <template v-for="th of tr">
                                <th
                                    :key="th.id"
                                    :colspan="th.colspan"
                                    :rowspan="th.rowspan"
                                    :class="th.colspan === 1 ? 'text-right' : 'text-center'"
                                    :style="{
                      width:
                        th.description === ''
                          ? '50px'
                          : th.key === 'region'
                          ? '30vw'
                          : 'auto',
                    }"
                                >
                                    {{ th.description }}
                                </th>
                            </template>
                        </tr>
                    </template>
                    </thead>
                    <tbody>
                    <template v-for="(item, itemIndex) of globalArr">
                        <tr
                            :key="`item${itemIndex}`"
                            :class="{ 'table-info': item.parent }"
                            v-if="item.parent || collapseAll"
                        >
                            <td colspan="1" class="text-center">
                                <button
                                    v-if="item.parent"
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="collapse"
                                >
                                    <img v-if="collapseAll" src="./icons/open.svg" alt="" />
                                    <img v-else src="./icons/closed.svg" alt="" />
                                </button>
                            </td>
                            <template v-for="(k, idx) of item.data">
                                 <!-- <td
                                    :key="`${item.id} - ${idx}`"
                                    :colspan="typeof k === 'string' ? 1 : k.colspan"
                                    :class="{
                        yellow: k.class === 'yellow' && item.parent,
                        lightYellow: k.class === 'yellow' && !item.parent,
                    'font-weight-bold': item.parent === true,
                        'text-left':
                                            typeof k === 'object' && k.title === 'region',
                                        'text-right':
                                            typeof k === 'string' || k.title !== 'region',
                                        }"
                                >
                                    {{
                                        getFormatted(item[typeof k === "string" ? k : k.title])
                                    }}
                                </td> -->
                  <td
                    :key="`${k.column_number}_${idx}`"
                    >
                    {{ getFormatted(k.value) }}
                                </td>
                            </template>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else class="spinner">
            <b-spinner
                variant="info"
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
            />
        </div>
    </div>
</template>

<script>
import { MonitoringSnpApi } from "@/modules/monitoring-snp/api/MonitoringSnpApi";
import {
    formAnalysisHeaders,
    tableFieldsOrder,
} from "@/modules/monitoring-snp/consts/form-analysis-headers";
import { isEmpty } from 'lodash';
import { Ax } from "@/utils";

export default {
    name: "form-analysis",
    props: ["years"],
    data() {
        return {
            collapseAll: true,
            filterLabels: [
                {
                    name_kk: "Форма",
                    name_ru: "Форма",
                    name_en: "Form",
                },
                {
                    name_kk: "Жыл",
                    name_ru: "Год",
                    name_en: "Year",
                },
            ],
            tableFields: [],
            tableKeys: [],
            globalArr: [],
            selectedReportType: {
                id: 14,
                name: "Демография",
            },
            selectedYear: 2021,
            data: [],
            loading: false,
            formHeaders: [],
            keysOrder: [],
            reportTypes: [],
            loadedYear: 2021,
            loadedReportType: {
                id: 14,
                name: "Демография",
            },
        maxRowSpan: 3
        };
    },
    watch: {
        selectedReportType: function () {
            this.selectedYear = null;
            this.loadYears();
        },
    },
    mounted() {
        this.loadReportTypes();
        this.loadFormAnalysis();
        this.getHeaders(14);

        // this.formHeaders = formAnalysisHeaders[3];
        // this.keysOrder = tableFieldsOrder[3];
    },
    methods: {
        getFormatted(str) {
            if (!str) {
                return str;
            }
            for (let i = 0; i < str.length; i++) {
                if (!(str[i] >= "0" && str[i] <= "9") && str[i] !== ".") {
                    return str;
                }
            }
            return Number(str).toLocaleString("ru-RU");
        },
        loadYears() {
            //${this.selectedReportType.id}
            MonitoringSnpApi.get(
                `/snp/api/v1/admin/years`
            )
                .then((res) => (this.years = res.data))
                .catch(() => {});
        },
        loadReportTypes() {
            MonitoringSnpApi.get("/snp/api/v1/report/analyzeForm/types")
                .then((res) => {
                    console.log('res.data', res.data);
                    this.reportTypes = res.data
                })
                .catch(() => {});
        },
        loadFormAnalysis() {
            this.loading = true;
            const reportId = this.selectedReportType?.id;
            const year = this.selectedYear;

            MonitoringSnpApi.get("/snp/api/v1/admin/form-analyze/v2", { reportId, year })
                .then((res) => {
                this.getHeaders(reportId);
                const parentRow = [
                    {
                        "column_number": "1",
                        "value": res.data.address.region,
                        "depth": 1,
                    },
                    ...res.data.data,
                ]

                    const sorted = [{data: parentRow, parent: true}, ...res.data.children].map((row) => ({
                        ...row,
                        data: row.data.sort((a, b) => +a.column_number - +b.column_number),
                    }));

                    this.loadGlobalArr(sorted);
                    this.loadedYear = year;
                    this.loadedReportType = { ...this.selectedReportType };
                })
                .catch(() =>
                    this.makeToast(
                        "danger",
                        "По указанным фильтрам не существует данных!",
                        "Ошибка"
                    )
                )
                .finally(() => (this.loading = false));
        },
        loadGlobalArr(data) {
            const reportId = this.selectedReportType?.id || 3;
            // this.formHeaders = formAnalysisHeaders[reportId];
            //   this.keysOrder = tableFieldsOrder[reportId];
            data.forEach((item, idx) => {
                this.globalArr.push({
                    data: item.data,
                    parent: item.parent,
                });
            });
        },
        reloadData() {
            this.globalArr = [];
            this.loadFormAnalysis();
        },
        applyFilters() {
            if (this.selectedReportType && this.selectedYear) {
                this.reloadData();
                this.$refs.drop.hide();
            } else {
                this.makeToast(
                    "info",
                    "Все поля фильтра должны быть заполнены",
                    "Ошибка"
                );
            }
        },
        resetFilters() {
            this.selectedYear = 2021;
            this.selectedReportType = {
                id: 3,
                name: "Демография",
            };
            this.formHeaders = formAnalysisHeaders[3];
            this.reloadData();
        },
        collapse() {
            this.collapseAll = !this.collapseAll;
        },
        makeToast(variant = null, toastBody, title) {
            this.$bvToast.toast(toastBody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true,
            });
        },
        downloadFile(url) {
            Ax(
                {
                    url,
                    method: 'GET',
                    data: null,
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.selectedReportType.name}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    console.log('err', error);
                }
            );
        },
        downloadReport() {
            if (isEmpty(this.selectedReportType)) {
                this.makeToast('danger', 'Вы не форму из списка', 'Ошибка');
                return;
            }
            const url = `/snp/api/v1/report/file/analyze?reportId=${this.selectedReportType.id}&year=${this.selectedYear}`;
            this.downloadFile(url);
        },
        downloadAllReports() { // Анализ формы по отраслям
            this.downloadFile(`/snp/api/v1/report/file/analyze/reports?year=${this.selectedYear}`);
        },
        downloadAllHistory() { // Анализ формы по годам
            this.downloadFile(`/snp/api/v1/report/file/analyze/years?reportId=${this.selectedReportType.id}`);
        },
        getHeaders(id) {
            MonitoringSnpApi.get(`/snp/api/v1/report/all-headers/${id}`)
                .then((res) => {
                this.formHeaders = this.organizeHeadersByDepth(res.data);
                })
                .catch(() => {});
            },
        organizeHeadersByDepth(headers) {
            const organized = headers.map((item) => ({
                ...item,
                colspan: this.calculateDescendants(item, headers),
                rowspan: this.getRowSpan(item, headers),
            }));

            const maxDepth = Math.max(...headers.map((item) => +item.depth));
            this.maxRowSpan = maxDepth + 1;
            const depthArrays = {};

            organized.forEach((item) => {
                if (!depthArrays[item.depth]) {
                depthArrays[item.depth] = [];
                }
                depthArrays[item.depth].push(item);
            });

            const result = Object.values(depthArrays);

            return result;
        },
        getRowSpan(item, headers) {
            if (
                item.depth === 1 &&
                item.parentId === 0 &&
                headers.filter((header) => +header.parentId === +item.id).length === 0
            ) {
                return Math.max(...headers.map((header) => header.depth));
            } else if (
                item.parentId === 0 &&
                headers.filter((header) => +header.parentId === +item.id).length !== 0
            ) {
                return 1;
            } else if (
                item.parentId !== 0 &&
                item.depth !== 1 &&
                headers.filter((header) => +header.parentId === +item.id).length === 0
            ) {
                return Math.max(...headers.map((header) => header.depth)) - 1;
            } else {
                return 1;
            }
        },
        calculateDescendants(item, headers) {
            const children = headers.filter((child) => child.parentId === item.id);
            let descendantsCount = 0;

            children.forEach((child) => {
                descendantsCount += this.calculateDescendants(child, headers);
            });

            if (descendantsCount === 0) {
                descendantsCount = 1;
            }

            item.descendantsCount = descendantsCount;

            return descendantsCount;
        },
        downloadStaticFile(id) {
            const url = `/snp/api/v1/report/file/infoTemplate/${id}`;
            const name = +id === 1 ? "СВОД 2022-I полугодие.xlsx" : "Режим Мониторинг СНП.pdf";
            Ax(
                {
                    url,
                    method: "GET",
                    data: null,
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    },
                },
                    (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", name);
                    document.body.appendChild(link);
                    link.click();
                    },
                    (error) => {
                    console.log("err", error);
                }
            );
        },
    },
};
</script>

<style scoped>
.table-container .table thead tr:not(.big-count) th {
    font-weight: 700;
}

.count-labels {
    text-align: right;
    font-weight: 600 !important;
}

.spinner {
    padding-top: calc(100vh / 3.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef1f8;
}

.table-name-col {
    width: 250px;
}

.table-col {
    width: 200px;
}

.yellow {
    background-color: #fbf7e2 !important;
    color: #000 !important;
}

.lightYellow {
    background-color: rgba(255, 245, 219, 0.5) !important;
}

.table-container .table td {
    width: 5%;
}

.table-container .table td:nth-child(2) {
    width: 20%;
}

.selected {
    margin-left: 20px;
    color: #1c2a3e;
    font-weight: 700;
    font-size: 14px;
}

.arrow-th{
  background-color: #F7F9FC !important;
  text-transform: uppercase;
  color: #6087A0 !important;
  font-size: 0.6666666667rem;
  font-weight: 800;
  line-height: 1.3;
  padding: 5px 8px;
}
</style>